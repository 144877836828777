import React from 'react';

import NodejsIcon from '../../../../../assets/images/stacks/node.js.inline.svg'
import ReactIcon from '../../../../../assets/images/stacks/react.inline.svg'
import NextjsIcon from '../../../../../assets/images/stacks/next.js.inline.svg'
import GraphqlIcon from '../../../../../assets/images/stacks/graphql.inline.svg'
import TypescriptIcon from '../../../../../assets/images/stacks/typescript.inline.svg'
import PythonIcon from '../../../../../assets/images/stacks/python.inline.svg'

import {
  SectionContainer,
  StackCard,
  StacksContainer,
  StacksList,
  StackTitle
} from './styles';

const stacks = [
  {
    id: 1,
    name: 'Node.js',
    Icon: NodejsIcon
  },
  {
    id: 2,
    name: 'React - React Native',
    Icon: ReactIcon
  },
  {
    id: 3,
    name: 'Next.js',
    Icon: NextjsIcon
  },
  {
    id: 4,
    name: 'GraphQL',
    Icon: GraphqlIcon
  },
  {
    id: 5,
    name: 'Typescript',
    Icon: TypescriptIcon
  },
  {
    id: 6,
    name: 'Python',
    Icon: PythonIcon
  },
]

const SectionStacks: React.FC = () => {
  return (
    // TODO: Projects, remove marginTop
    <SectionContainer id='section-stacks' style={{ marginTop: 0 }}>
      <StackTitle variant='h2'>
        Stacks that I usually work with
      </StackTitle>

      <StacksContainer>
        <StacksList>
          {stacks.map(stack => (
            <StackCard key={stack.id} title={stack.name} >
              <stack.Icon key={stack.id} />
            </StackCard>
          ))}
        </StacksList>
      </StacksContainer>
    </SectionContainer>
  )
}

export default SectionStacks;

import { fadeIn, fadeOut, styled } from "../../../../../stitches.config";

import Arrow from '../../../../../assets/images/arrow-strong.inline.svg';

import { TypographyH1 } from "../../../../Typography/styles";

export const SectionContainer = styled('section', {
  display: 'flex',
  flexDirection: 'column',

  marginTop: '$section',

  '@medium': {
    alignItems: 'center',
  }
})

export const TextAnchorContainer = styled('a', {
  position: 'relative',

  display: 'flex',
  alignItems: 'baseline',

  width: 'fit-content',
  marginTop: '$2',

  textDecoration: 'none',

  '&:hover::before': {
    opacity: 1,
    animation: `${fadeIn} 500ms`,
  },

  '&::before': {
    content: '',
    position: 'absolute',

    left: 0,
    right: 0,
    bottom: '-$1',

    height: '8px',
    background: '$commonBlack',

    opacity: 0,
    animation: `${fadeOut} 200ms`,
  },
})

export const WorkTogetherTypography = styled(TypographyH1, {
  maxWidth: '243px',

  '@medium': {
    maxWidth: 'none'
  },
})

export const ArrowStrong = styled(Arrow, {
  width: '26px',
  height: '26px',

  marginTop: 'auto',
  paddingBottom: '10px',

  '@medium': {
    width: '36px',
    height: '36px',

    marginLeft: '$3',

    marginTop: 0,
    paddingBottom: 0,
  }
})

import { keyframes, styled } from "../../../../../../stitches.config";
import Arrow from '../../../../../../assets/images/arrow.inline.svg';

const scrollDown = keyframes({
  '0%': {
    transform: 'translateY(0px) rotate(0deg)',
  },

  '25%': {
    transform: 'rotate(45deg)'
  },

  '50%': {
    transform: 'translateY(19px) rotate(45deg)',
  },

  '51%': {
    opacity: '1',
    transform: 'translateY(19px) rotate(45deg)',
  },

  '52%': {
    opacity: '0',
    transform: 'translateY(19px) rotate(45deg)',
  },

  '53%': {
    opacity: '0',
    transform: 'translateY(-19px) rotate(45deg)',
  },

  '54%': {
    opacity: '1s',
    transform: 'translateY(-19px) rotate(45deg)',
  },

  '75%': {
    transform: 'translateY(0px) rotate(45deg)',
  },

  '100%': {
    transform: 'translateY(0px) rotate(0deg)',
  },
})

export const ScrollDownContainer = styled('div', {
  position: 'absolute',
  left: '50%',
  bottom: '12%',

  transform: 'translate(-50%, -50%)',
  overflow: 'hidden',

  userSelect: 'none',
  cursor: 'default',

  span: {
    textTransform: 'uppercase'
  }
})

export const ScrollDownArrow = styled(Arrow, {
  width: '7px',
  height: '7px',

  marginLeft: '$1',

  '&.running': {
    animation: `${scrollDown} 1000ms`,
  },

  '@medium': {
    width: '8px',
    height: '8px',
  },

  '@large': {
    width: '9px',
    height: '9px',
  }
})

import React, { useCallback, useEffect } from 'react';
import Typography from '../../../../../Typography';
import { ScrollDownArrow, ScrollDownContainer } from './styles';

const ScrollDown: React.FC = () => {
  const handleGoToNextSection = useCallback(() => {
    // TODO: Projects
    // const sectionProjects = document.querySelector('#section-projects')

    const sectionProjects = document.querySelector('#section-stacks')

    if (sectionProjects) {
      sectionProjects.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  const handleStartAnimation = useCallback(() => {
    const arrow = document.querySelector('.scroll-down .arrow')!
    arrow.classList.add('running')
  }, [])

  useEffect(() => {
    const arrow = document.querySelector('.scroll-down .arrow')!

    function handleAnimationEnd() {
      arrow.classList.remove('running')
    }

    arrow.addEventListener('animationend', handleAnimationEnd, false)

    return () => {
      arrow.removeEventListener('animationend', handleAnimationEnd, false)
    }
  }, [])

  return (
    <ScrollDownContainer
      className='scroll-down'
      onClick={handleGoToNextSection}
      onMouseEnter={handleStartAnimation}
    >
      <Typography variant='body2'>Scroll Down</Typography>

      <ScrollDownArrow className='arrow' />
    </ScrollDownContainer>
  )
}

export default ScrollDown;

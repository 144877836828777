import React from 'react';

import Typography from '../../../../Typography';

import { ArrowStrong, SectionContainer, TextAnchorContainer, WorkTogetherTypography } from './styles';

const SectionContact: React.FC = () => {
  return(
    <SectionContainer id='section-contact'>
      <Typography variant='subtitle1'>Want to build something amazing?</Typography>

      <TextAnchorContainer href="mailto:garcia.idaslon@gmail.com">
        <WorkTogetherTypography size='small'>
          Let's work together
        </WorkTogetherTypography>

        <ArrowStrong />
      </TextAnchorContainer>
    </SectionContainer>
  )
}

export default SectionContact;

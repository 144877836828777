import { styled } from "../../../../../stitches.config";

export const SectionContainer = styled('section', {
  position: 'relative',

  display: 'flex',
  flexDirection: 'column',

  minHeight: '100vh',
  textAlign: 'center',

  '.center': {
    flex: 1
  },

  'h1, h3': {
    textAlign: 'center'
  }
})

import React from 'react';

import BoxCenter from '../../../../BoxCenter';
import Typography from '../../../../Typography';
import ScrollDown from './ScrollDown';

import { SectionContainer } from './styles';

const SectionHello: React.FC = () => {
  return (
    <SectionContainer id='section-hello'>
      <BoxCenter>
        <Typography variant='h1'>Hello</Typography>
        <Typography variant='h3' as='h2'>I am Idaslon, a Software Engineer.</Typography>
      </BoxCenter>

      <ScrollDown />
    </SectionContainer>
  )
}

export default SectionHello;

import React from "react"

import ReturnToTop from "../components/ReturnToTop";
import SectionContact from "../components/pages/home/sections/SectionContact";
import SectionHello from "../components/pages/home/sections/SectionHello";
import SectionProjects from "../components/pages/home/sections/SectionProjects";
import SectionStacks from "../components/pages/home/sections/SectionStacks";

const Home: React.FC = () => {
  return (
    <>
      <ReturnToTop />

      <SectionHello />

      {/* TODO: Projects */}
      {/* <SectionProjects /> */}
      <SectionStacks />
      <SectionContact />
    </>
  )
}

export default Home

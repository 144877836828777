import { spacing, styled } from "../../../../../stitches.config";
import Typography from "../../../../Typography";

export const SectionContainer = styled('section', {
  display: 'flex',
  flexDirection: 'column',

  columnGap: '$3',
  marginTop: '$section',

  '@large': {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
})

export const StackTitle = styled(Typography, {
  '@medium': {
    marginInline: 'auto',

    maxWidth: '417px',
    textAlign: 'center',
  },

  '@large': {
    minWidth: '366px',
    maxWidth: '366px',

    marginInline: '0',
    textAlign: 'start',
  },
})

export const StacksContainer = styled('div', {
  width: '100%',
  marginTop: spacing(2.5),

  '@medium': {
    maxWidth: '472px',
    marginInline: 'auto',
  },

  '@large': {
    marginInline: 0,
  },

  '@extraLarge': {
    maxWidth: '724px',
  }
})

export const StacksList = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(147px, 1fr))',

  justifyItems: 'center',

  rowGap: '$4',
  columnGap: spacing(2.25),

  '@medium': {
    columnGap: spacing(4),
  },

  '@large': {
    justifyItems: 'flex-end',
  },

  '@extraLarge': {
    rowGap: '$6',
    columnGap: '$4',

    gridTemplateColumns: 'repeat(auto-fill, minmax(176px, 1fr))',
  }
})

export const StackCard = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: '100%',
  maxWidth: '147px',

  paddingBlock: spacing(1.25),
  borderRadius: '8px',

  boxShadow: '$elevation2',
  background: '$commonWhite',

  svg: {
    width: '104px',
    height: '104px',
  },

  '@medium': {
    maxWidth: '220px',

    svg: {
      width: '170px',
      height: '170px',
    },
  }
})
